import React, {useState, useRef, useEffect} from "react";
import "./normalcontrol.css";

function NormalControl() {
    const [output, setOutput] = useState(["Welcome to the JES-Portfolio console!"]);
    const [command, setCommand] = useState("");
    const [logonStep, setLogonStep] = useState(0); // Tracks the logon step: 0 = not logging in, 1 = email entered, 2 = password entered
    const [email, setEmail] = useState(""); // Temporarily stores the entered email
    const outputRef = useRef(null);

    const handleCommandInput = (e) => {
        setCommand(e.target.value);
    };

    const handleCommandSubmit = (e) => {
        e.preventDefault();

        if (command.trim()) {
        // Logon flow
        if (logonStep === 0 && command.startsWith("logon ")) {
            const enteredEmail = command.split(" ")[1];
            if (enteredEmail) {
            setOutput((prevOutput) => [...prevOutput, `> ${command}`, "Please enter your password:"]);
            setEmail(enteredEmail);
            setLogonStep(1); // Proceed to password step
            } else {
            setOutput((prevOutput) => [...prevOutput, `> ${command}`, "Usage: logon [Email]"]);
            }
        } else if (logonStep === 1) {
            setOutput((prevOutput) => [...prevOutput, "Password accepted!"]);
            setLogonStep(0); // Reset logon flow
            setEmail(""); // Clear email state
        } else {
            // Handle other commands
            let response = `Unknown command: ${command}`;

            if (command === "help") {
                response = `
                ╒═════════════════════╤══════════════════════════════╕
                │ Command             │ Description                  │
                ╞═════════════════════╪══════════════════════════════╡
                │ help                │ Infos to commands            │
                │ clear               │ Clears the console           │
                │ logon [Email]       │ Logs in with specified email │
                ╘═════════════════════╧══════════════════════════════╛
            `;
            } else if (command === "clear") {
            setOutput([]); // Clear console
            setCommand("");
            return; // Exit early
            }

            setOutput((prevOutput) => [...prevOutput, `> ${command}`, response]);
        }

        setCommand(""); // Clear the input after processing
        }
    };

    // Auto-scroll to bottom when output updates
    useEffect(() => {
        if (outputRef.current) {
        outputRef.current.scrollTop = outputRef.current.scrollHeight;
        }
    }, [output]);

    return (
        <div className="console-container">
        <div className="console-output" ref={outputRef}>
            {output.map((line, index) => (
            <div key={index}>{line}</div>
            ))}
        </div>
        <form className="console-input" onSubmit={handleCommandSubmit}>
            <input
                type={logonStep === 1 ? "password" : "text"}
                value={command}
                onChange={handleCommandInput}
                placeholder="Type a command..."
                autoFocus
            />
        </form>
        </div>
    );
}

export default NormalControl;