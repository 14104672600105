import React, { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      // API endpoint
      const url = "https://jes-backend-vercel.vercel.app/";

      axios
          .get(url)
          .then((response) => {
              setData(response.data);
              setLoading(false);
          })
          .catch((error) => {
              setError(error.message);
              setLoading(false);
          });
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const HandleNavigationClick = (dest) => {
    navigate(dest);
  }

  return (
      <div>
        <div className='div-head'>
          <Helmet>
            <title>JES-Portfolio</title>
            <meta name='description' content='This is a Porfolio website for Jost Schienke'/>
          </Helmet>
        </div>
        <div className='div-body'>
          <h1>In The making</h1>
          <pre>API: {JSON.stringify(data, null, 2)}</pre>

          <button onClick={() => HandleNavigationClick("/normal")}>Normal Navigation</button>
          <button onClick={() => HandleNavigationClick("/easy")}>Easy Navigation</button>
        </div>
      </div>
  );
}

export default App;
